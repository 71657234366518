<template>
    <div class="carve">
        <h5header :name="$t('reward.desc136')" :right="false" />
        <div class="carve-con">
            <div class="modal-tips" v-if="prizeId == 1">
                <div>{{ $t('reward.desc135') }}</div>
                <div>1.{{ $t('reward.desc114', {
                    n1: configInfo.activityParamOne * 100, n2: configInfo.activityParamThree
                        * 100
                }) }}</div>
                <div>{{ $t('reward.desc133') }}</div>
            </div>
            <div class="modal-tips" v-if="prizeId == 2">
                <div>{{ $t('reward.desc135') }}</div>
                <div>1.{{ $t('reward.desc115', {
                    n1: configInfo.activityParamOne * 100, n2: configInfo.activityParamTwo *
                        100
                }) }}</div>
                <div>{{ $t('reward.desc134') }}</div>
            </div>
            <div class="modal-tab flex">
                <div class="flexcenter" :class="{ priact: prizeId == 1 }" @click="getRecord(1)">{{ $t('reward.desc137')
                }}</div>
                <div class="flexcenter" :class="{ priact: prizeId == 2 }" @click="getRecord(2)">{{ $t('reward.desc138')
                }}</div>

            </div>
            <div class="tbody-info" v-for="item in carveList" :key="item.id">
                <div class="info flex">
                    <div>{{ $t('reward.desc128') }}</div>
                    <p><span v-if="prizeId == 1">{{ $t('reward.desc137') }}</span><span v-else>{{
                        $t('reward.desc138') }}</span></p>

                </div>
                <div class="info flex">
                    <div>{{ $t('reward.desc129') }}</div>
                    <p>{{ item.allAmount }} DU</p>
                </div>
                <div class="info flex">
                    <div>{{ $t('reward.desc121') }}</div>
                    <p>{{ common.formatDate1(item.startTime) }} - {{ common.formatDate1(item.endTime) }}</p>
                </div>
                <div class="info flex">
                    <div>{{ $t('reward.desc130') }}</div>
                    <p>{{ common.formatDate(item.grantTime) }}(GST)</p>
                </div>
                <div class="info flex">
                    <div>{{ $t('reward.desc123') }}</div>
                    <p><span v-if="item.useState == 1">{{ $t('reward.desc124') }}</span>
                        <span v-else>{{ $t('reward.desc125') }}</span>
                    </p>
                </div>
                <div class="info flex">
                    <div>{{ $t('reward.desc131') }}</div>
                    <p @click="$router.push(`/record/detail?id=${item.awardCoinId}&act=${item.awardAccountType}`)">{{
                        $t('reward.desc132') }}</p>
                </div>
            </div>
            <div class="no-data" v-if="showNull">
                <img src="../../public/images/no-data.png" alt="" />
                <span>{{ $t("record.desc13") }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
    components: {
        h5header
    },
    data() {
        return {
            title: '',
            carveList: [],
            showNull: false,
            configInfo: {},
            prizeId: 1
        }
    },
    mounted() {

        this.$post(this.URL.reward.land, {
            activityType: 2
        }).then(res => {
            if (res.code == 0) {
                if (res.data.awardAccountType == 1) {
                    res.data.awardAccountTypeName = this.$t('new.desc7')
                } else if (res.data.awardAccountType == 2) {
                    res.data.awardAccountTypeName = this.$t('new.desc8')
                } else {
                    res.data.awardAccountTypeName = this.$t('new.desc9')
                }
                this.configInfo = res.data;
            }
        })
        this.getlist();
    },
    methods: {
        getRecord(i) {
            this.showNull = false;
            this.carveList = [];
            this.prizeId = i;
            this.getlist();
        },
        getlist() {
            this.$post(this.URL.reward.prize, {
                type: this.prizeId
            }).then(res => {
                if (res.code == 0) {
                    if (res.data == null) {
                        this.showNull = true;
                        return;
                    }
                    res.data.forEach( e =>{
                        if(e.useState==1){
                            this.carveList.push(e)
                        }
                    })
                    if (res.data.length > 0) {
                        this.carveList.forEach(e => {
                            let time1 = 4 * 60 * 60 * 1000;
                            e.grantTime = e.grantTime - time1;
                            e.startTime=e.startTime -time1;
                            e.endTime=e.endTime -time1;
                        })
                    }
                } else {
                    this.$message(res.message)
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.carve {
    padding: 56px 16px;

    .modal-tips {
        margin-bottom: 10px;
        border-radius: 8px;
        background: #181818;
        padding: 16px 4px 16px 16px;
        font-size: 13px;
        color: #fff;

        div {
            &:first-child {
                color: #E15757;
            }

        }
    }

    .modal-tab {
        margin: 20px 0;

        div {
            flex: 0 0 76px;
            height: 36px;
            margin-right: 16px;
            background: url('../../public/images/reward/btnbg9.png') center no-repeat;
            background-size: 100% 100%;
            text-align: center;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.6);
            cursor: pointer;

            &:hover {
                background: url('../../public/images/reward/btnbg10.png') center no-repeat;
                background-size: 100% 100%;
                color: #000000;
            }
        }

        .priact {
            background: url('../../public/images/reward/btnbg10.png') center no-repeat;
            background-size: 100% 100%;
            color: #000000;
        }
    }

    .tbody-info {
        margin-bottom: 10px;
        padding: 16px;
        border-radius: 8px;
        background: #181818;

        .info {
            margin-bottom: 16px;
            font-size: 13px;
            color: rgba(255, 255, 255, 0.6);

            &:last-child {
                margin-bottom: 0;
            }

            div {
                flex: 0 0 40%;
            }

            p {
                flex: 0 0 60%;
                text-align: right;
                color: #fff;
            }
        }
    }
}

.no-data {
    width: 100%;
    padding: 40px 0;

    img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
    }

    span {
        display: block;
        margin: 0 auto;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #a1a1a1;
    }
}
</style>